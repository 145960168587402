$primary: #ffb127 !default;
$primary-text-color: #222 !default;

@import "~react-dates/lib/css/_datepicker.css";

@import "course";
@import "datepicker";
@import "form";
@import "order-card";
@import "price";
@import "product-image";
@import "progressbar";
