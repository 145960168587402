$product-image-placeholder-color: #b4b4b4 !default;

.product-image-placeholder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .product-image-placeholder-content {
    color: $product-image-placeholder-color;
    font-size: 2rem;
  }
}
