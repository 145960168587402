.order-card {
  .order-card-products-container {
    width: calc(100% + 2 * 1.25rem);
    margin-left: -1.25rem;
    margin-right: -1.25rem;

    .order-card-products-scroll {
      overflow-x: auto;
      white-space : nowrap;

      padding-left: 1.25rem;
      padding-right: 1.25rem;

      &::-webkit-scrollbar {
        height: 14px;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #AAAAAA;
        border-left: 1.25rem solid transparent;
        border-right: 1.25rem solid transparent;
      }

      .order-card-product {
        display: inline-block;
        margin-right: 0.5rem;
      }
      .order-card-product:last-of-type {
        margin-right: 0;
      }
    }
  }
}
