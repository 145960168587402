.product-page-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .product-page-price {
    position: relative;
    color: #dc3545;
    font-size: 2.5rem;
    font-weight: 500;
    .product-page-price-symbol,
    .product-page-price-cents {
      position: relative;
      font-size: 1.2rem;
      top: -14px;
    }
    .product-page-price-cents {
      margin-left: -5px;
    }
  }
  .product-page-original-price {
    position: relative;
    color: #595959;
    font-size: 1.2rem;
    font-weight: 500;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      border-top: 2px solid;
      transform: rotate(-10deg);
    }
  }
}
